import React from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';

const GET_BLOGS = gql`
  query {
   blogPostsCollection {
      items {
        title
        blogUrl
        featuredImage {
          url
          title
        }
      }
    }
  }
`;

const BlogsPage = () => {
  const { loading, error, data } = useQuery(GET_BLOGS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const blogs = data.blogPostsCollection.items.map((blog, index) => ({
    id: index,
    title: blog.title,
    image: blog.featuredImage.url,
    url: `blogs/${blog.blogUrl}`,
  }));

  return (
    <>
      <Helmet>
        <title>Party Snack Recipies - Snackster</title>
        <meta name="description" content={`Find creative and easy Party snack recipes at Snackster. Explore step-by-step guides to make delicious treats and beverages for every occasion. Start cooking today!`} />
      </Helmet>
      <div className="party-more-fun-container py-5">
        <h1 className="party-title pt-5 text-center">Blogs</h1>
        <div className="container mt-5 mb-4">
          <div className="row">
            {blogs.map((blog) => (
              <div key={blog.id} className="col-md-3">
                <Link to={`/${blog.url}`}>
                <div className='blog-card'>
                    <img src={blog.image} alt={blog.title} />
                </div>
                    <h2 className="text-center blog-title">{blog.title}</h2>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogsPage;
