import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet-async';


const GET_BLOG_DETAIL = gql`
  query GetBlogDetail($blogUrl: String!) {
   blogPostsCollection(where: { blogUrl: $blogUrl }) {
      items {
        title
        metaTitle
        metaDescription
        featuredImage {
          url
          title
        }
        blogContent
      }
    }
}
`;

const BlogDetail = () => {
  const { blogUrl } = useParams();
  const { loading, error, data } = useQuery(GET_BLOG_DETAIL, {
    variables: { blogUrl },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const blog = data.blogPostsCollection.items[0];
  const { title, featuredImage, blogContent } = blog;

  return (
    <>
      <Helmet>
        <title>{blog.metaTitle} | Snackster</title>
        <meta name="description" content={blog.metaDescription} />
      </Helmet>
      <div>
        <div className='bg-pink py-5'>
          <div className="container pt-3 mt-5">
            <h1 className='text-uppercase text-center blog-heading'>{title}</h1>
            <center><img src={featuredImage.url} alt={featuredImage.title} className="w-75" loading="lazy" /></center>
            <div className='blog-content'><ReactMarkdown>{blogContent}</ReactMarkdown></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
